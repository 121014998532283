import RankLabel from '../../RankLabel';
import styles from '../styles.module.scss';
import skeleton from './styles.module.scss';
import { RankProgressSkeletonProps } from './props';

export default function RankProgressSkeleton({
  isRow,
}: RankProgressSkeletonProps) {
  return (
    <div
      className={`${styles.container} ${isRow ? styles['container--row'] : ''}`}
    >
      {!isRow && (
        <div
          className={`${styles.container__row} ${styles['container__row--between']}`}
        >
          <div
            className={`${styles.container__row} ${styles['container__row--small']}`}
          >
            <div className={skeleton.text} />
            <div className={skeleton.text} />
          </div>

          <div className={skeleton.text} />
        </div>
      )}
      {isRow && (
        <div className={styles.container__row__labelprogress}>
          <div className={skeleton.text} />
          <div className={skeleton.text} />
        </div>
      )}
      <div
        className={`${styles['container__progress-container']} ${isRow ? styles['container__progress-container--row'] : ''}`}
      >
        <div className={skeleton.progress} />
      </div>
      <div
        className={`${styles.container__row} ${styles['container__row--between']}`}
      >
        {!isRow && (
          <>
            <RankLabel rank={{} as any} loading={true} />
            <RankLabel rank={{} as any} loading={true} />
          </>
        )}
      </div>
    </div>
  );
}
