import { useTopPayouts } from '@/api/games';
import Carousel from '@/components/Home/Carousel';
import { useMemo } from 'react';
import { MostPaidCarouselProps } from './props';
import GameCard from '@/components/core/Games/GameCard/Versions/Primary';
import { ErrorCard } from '@package/components';
import { getComputedColor } from '@/utils/theme';

export default function MostPaidCarousel({
  isSkeleton,
}: MostPaidCarouselProps) {
  const { games, isLoading, refetch, error } = useTopPayouts();

  const Bets = useMemo(
    () =>
      games?.map(game => (
        <GameCard
          key={`home-biggest-wins-card-${game?.id}`}
          game={game}
          showTotalPaid
          largerMobileCardSize
        />
      )),
    [games],
  );

  const Skeletons = useMemo(
    () =>
      Array.from({ length: 20 }).map((_, idx) => (
        <GameCard
          key={`home-most-paid-card-${idx}-skeleton`}
          isLoading
          showTotalPaid
          largerMobileCardSize
        />
      )),
    [],
  );

  if (error) {
    return <ErrorCard error={error} refetch={refetch} />;
  }

  return (
    <Carousel
      label="common:most_paid_label"
      useTransComponent
      color={getComputedColor('--warning-color')}
      icon="fa-duotone fa-badge-dollar"
    >
      {isLoading || isSkeleton ? Skeletons : Bets}
    </Carousel>
  );
}
