import styles from '../styles.module.scss';
import skeleton from './styles.module.scss';

export default function GameCardBiggestWinsSkeleton() {
  return (
    <div className={styles.card}>
      <div className={skeleton.image} />

      <div className={styles.card__content}>
        <div className={styles.card__row}>
          <div className={skeleton.text} />

          <div className={skeleton.multiplier} />
        </div>

        <div className={styles.card__content__wrapper}>
          <div className={skeleton.coin} />
          <div className={skeleton.value} />
        </div>

        <div className={styles.card__description}>
          <div className={skeleton.text} />
        </div>
      </div>
    </div>
  );
}
