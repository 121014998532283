import { useUserAccount } from '@/api/user/queries';
import { ErrorChip } from '@starsoft/common/components';
import styles from '../styles.module.scss';
import useTranslation from 'next-translate/useTranslation';

import { ModalsKey } from '@/enums/modalsKey';
import { authOptions } from '@/constants/authOptions';
import RankProgress from '@/components/RankProgress';
import { useRankStatusByUser } from '@/api/rank-status';
import { RankStatus } from '@/models/rank/rankStatus';
import { Button } from '@starsoft/common/components';
import Link from '@/components/core/Link';
import { IconButton } from '@starsoft/common/components';
import { useRouter } from 'next/router';
import { Image } from '@starsoft/common/components';

export default function MainBannerContentRenderer() {
  const { push } = useRouter();
  const { user, error, refetch, isAuthorized } = useUserAccount();
  const { isLoading, rankStatus } = useRankStatusByUser({
    enabled: isAuthorized,
  });
  const { t } = useTranslation('common');

  if (error) {
    <ErrorChip action={refetch} />;
  }

  if (!!user) {
    return (
      <div className={styles.container__rank__progress__container}>
        <RankProgress
          loading={isLoading}
          rankStatus={rankStatus as RankStatus}
        />
      </div>
    );
  }

  function handleRoute(path: string) {
    push(path);
  }

  return (
    <div className={styles.container__button__row}>
      <Link modal={ModalsKey.Register}>
        <Button isSmall>{t('register_button')}</Button>
      </Link>
      <div className={styles.container__row}>
        {authOptions.map(media => (
          <IconButton
            key={`media_button_${media.name}`}
            onClick={() => handleRoute(media.url)}
          >
            {(media.image && (
              <Image
                width={20}
                height={20}
                src={media.image}
                alt={`icon-image-media-${media.name}`}
              />
            )) || <i className={`fa-brands fa-${media.name}`} />}
          </IconButton>
        ))}
      </div>
    </div>
  );
}
