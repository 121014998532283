import styles from './styles.module.scss';
import { Image as CoreImage } from '@starsoft/common/components';
import StoriesModal from '@/components/core/Stories/Modal';
import { useModalNavigation } from '@/hooks/modals/useModalNavigation';
import { ModalsKey } from '@/enums/modalsKey';
import { stories } from '@/constants/stories';
import { useCallback, useMemo } from 'react';
import { Slider } from '@starsoft/common/components';

export default function HomeStories() {
  const { navigateToModal } = useModalNavigation();

  const handleNavigate = useCallback(
    (position: number) => navigateToModal(ModalsKey.Story, `?item=${position}`),
    [navigateToModal],
  );

  const Icons = useMemo(
    () =>
      stories.map((banner, index) => (
        <div className={styles.icon} key={`story-item-${index}`}>
          <CoreImage
            width={50}
            height={50}
            onClick={() => handleNavigate(index)}
            src={banner}
            className={styles.icon__image}
            style={{
              animationDelay: `${Number(index + 0.5)}s !important`,
            }}
          />
        </div>
      )),
    [handleNavigate],
  );

  return (
    <>
      <StoriesModal />
      <Slider hideButtons>{Icons}</Slider>
    </>
  );
}
