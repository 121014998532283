import { useUnifiedBets } from '@/api/unified-bets/queries/useUnifiedBets';
import { latestBetsColumns } from '@/constants/latest-bets';
import { LatestBetsProps } from './props';
import { ErrorCard, TableGrid } from '@starsoft/common/components';
import { BetType } from '@/models/bet/type';
import { useIsMobile } from '@package/hooks';
import { useMemo } from 'react';

function LatestBets({ isSkeleton }: LatestBetsProps) {
  const isMobile = useIsMobile();

  const { bets, error, isLoading, refetch } = useUnifiedBets({
    refetchOnWindowFocus: false,
    type: BetType.Casino,
    limit: 40,
  });

  const columns = useMemo(() => {
    if (!isMobile) {
      return latestBetsColumns;
    }

    return latestBetsColumns?.filter(
      col => col?.headerName != 'table_invite_header_bet_amount',
    );
  }, [isMobile]);

  if (error) {
    return <ErrorCard error={error} refetch={refetch} />;
  }

  return (
    <TableGrid
      rows={bets?.data ?? []}
      isLoading={isLoading || isSkeleton}
      columns={columns}
      isSecondaryStyles
      useAutoScroll
      preventOrder
    />
  );
}

export default LatestBets;
