import { Image } from '@starsoft/common/components';
import styled from 'styled-components';

export const Wrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  gap: 8px;
  color: ${({ theme }) => theme.vars.palette.text.primary};
`;

export const GameIcon = styled.i`
  width: 14px;
`;

export const GameImage = styled(Image)`
  width: 28px;
  height: 28px;
  aspect-ratio: 28 / 28;
  border-radius: ${({ theme }) => theme.vars.shape.borderRadius};
`;
