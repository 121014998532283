import baseAPI from '@/api';
import { UnifiedBet } from '@/models/bet/unified';
import { PaginatedResponse } from '@/models/pagination/paginated-response.dto';
import { QueryFunctionContext } from '@tanstack/react-query';
import { UseUnifiedBetsQueryKey } from './props';
import { UnifiedBetRequestDto } from '@/services/dto/unified-bets/unified-bet-request.dto';
import { AxiosResponse } from 'axios';

export async function fetchUnifiedBets({
  queryKey,
}: QueryFunctionContext<UseUnifiedBetsQueryKey>): Promise<
  PaginatedResponse<UnifiedBet>
> {
  const params: UnifiedBetRequestDto = queryKey[1];

  const response: AxiosResponse<PaginatedResponse<UnifiedBet>> =
    await baseAPI.get<PaginatedResponse<UnifiedBet>>('/unified-bets', {
      params,
    });

  return response.data;
}
