import { Tabs } from '@package/components';
import { CarouselsBiggestWinsFilterProps } from './props';

export default function CarouselsBiggestWinFilter({
  currentOption,
  onOptionClick,
  options,
}: CarouselsBiggestWinsFilterProps) {
  return (
    <Tabs
      isTiny
      currentTab={currentOption}
      tabs={options}
      showIndicator
      setTab={onOptionClick}
    />
  );
}
