import { Game } from '@/models/game';
import { TableGameCellProps } from './props';
import { GameIcon, GameImage, Wrapper } from './styles';
import { BetType } from '@/models/bet/type';

export default function TableGameCell({ bet, type }: TableGameCellProps) {
  const game: Game = bet?.game;

  function getIcon(): string | undefined {
    switch (type) {
      case BetType.Sportsbook:
        return 'fa-solid fa-basketball';
      case BetType.Slot:
        return 'fa-solid fa-cherries fa-swap-opacity';
    }
  }

  return (
    <Wrapper>
      {type === BetType.Casino ? (
        <GameImage
          src={`${process.env.NEXT_PUBLIC_S3_BUCKET_BASE_URL}/games/100x100${game?.image}`}
          alt={game?.name}
        />
      ) : (
        <GameIcon className={getIcon()} />
      )}
      {game?.name}
    </Wrapper>
  );
}
