import { useCallback, useEffect, useState } from 'react';
import { UseStoriesProps } from './props';
import { useCountUp } from 'use-count-up';
import { useRouter } from 'next/router';
import { usePathname } from '../usePathname';
import { useSearchParams } from '../useSearchParams';

export function useStories({ stories }: UseStoriesProps) {
  const search = useSearchParams();
  const item = search.get('item') ?? 0;

  const [current, setCurrent] = useState<number>(Number(item));
  const { push } = useRouter();
  const pathname: string = usePathname();

  const handleNextStory = useCallback(() => {
    if (current == stories.length - 1) {
      push(pathname.split('?')[0]);
      return;
    }

    setCurrent(prev => prev + 1);
  }, [current, stories, pathname, push]);

  const handlePreviousStory = useCallback(() => {
    if (current == 0) {
      return;
    }

    setCurrent(prev => prev - 1);
  }, [current]);

  const { value, reset } = useCountUp({
    decimalPlaces: 2,
    duration: 5,
    start: 5,
    isCounting: true,
    updateInterval: 0,
    end: 0,
    easing: 'linear',
    onComplete: () => handleNextStory(),
  });

  function handleReset() {
    if (typeof window == 'undefined') {
      return;
    }
    reset();
  }

  useEffect(handleReset, [current, reset]);

  return {
    current,
    handleNextStory,
    handlePreviousStory,
    countdown: value,
  };
}
