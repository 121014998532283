import { Auth } from './auth';

export const authOptions: Auth[] = [
  {
    name: 'google',
    url: process.env.NEXT_PUBLIC_GOOGLE_OAUTH_URL as string,
    image: `${process.env.NEXT_PUBLIC_S3_BUCKET_BASE_URL}/artworks/google-icon.svg`,
  },
  {
    name: 'discord',
    url: process.env.NEXT_PUBLIC_DISCORD_OAUTH_URL as string,
    image: `${process.env.NEXT_PUBLIC_S3_BUCKET_BASE_URL}/artworks/discord-icon.svg`,
  },
];
