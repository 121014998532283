import styles from './styles.module.scss';
import { m as motion } from 'framer-motion';
import { slideImage } from './variants';
import { StoryImageProps } from './props';
import { Button } from '@starsoft/common/components';

export default function StoryImage({ story }: StoryImageProps) {
  return (
    <div className={styles.container}>
      <motion.img
        src={story.url}
        variants={slideImage}
        className={styles.container__image}
        initial="hidden"
        animate="visible"
        exit="exit"
      />
      <div className={styles.container__content}>
        <h3 className={styles.container__content__title}>
          Compete with other players
        </h3>
        <p className={styles.container__content__description}>
          Participate in tournaments with other player and agains other players
          to win rare prizes
        </p>
      </div>
      <Button>Play now</Button>
    </div>
  );
}
