import { ModalsKey } from '@/enums/modalsKey';

import styles from './styles.module.scss';
import { AnimatePresence } from 'framer-motion';
import Stories from '..';
import { useModal } from '@/hooks/modals/useModal';
import { stories } from '@/constants/stories';
import { Story } from '../props';
import { useMemo } from 'react';
import { Modal } from '@starsoft/common/components';

export default function StoriesModal() {
  const { handleContainer, isOpen, onClose } = useModal(ModalsKey.Story);
  const formattedStories: Story[] = useMemo(
    () =>
      stories.map(story => ({
        duration: 5000,
        type: 'image',
        url: story,
      })),
    [],
  );

  return (
    <Modal open={isOpen} onClose={onClose}>
      <i
        className={`${styles.container__close} fa-duotone fa-circle-xmark`}
        onClick={() => onClose()}
      />
      <div className={styles.container} onClick={handleContainer}>
        {isOpen && (
          <AnimatePresence mode="wait">
            <Stories stories={formattedStories} />
          </AnimatePresence>
        )}
      </div>
    </Modal>
  );
}
