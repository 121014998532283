import { ModalsKey } from '@/enums/modalsKey';
import { TableUserProps } from './props';
import styles from './styles.module.scss';
import Link from '@/components/core/Link';
import useTranslation from 'next-translate/useTranslation';
import { ProfileStatus } from '@/enums/profileStatus';

export default function TableUser({ player }: TableUserProps) {
  const { t } = useTranslation('common');
  const isPrivate: boolean = player?.status !== ProfileStatus.Visible;

  return (
    <Link
      className={`${styles.container} ${isPrivate ? styles['container--disabled'] : ''}`}
      modal={ModalsKey.UserProfile}
      href={`?player-slug=${player?.slug}`}
    >
      {isPrivate && <i className="fa-duotone fa-user-secret" />}
      {isPrivate ? t('private_label') : player?.username}
    </Link>
  );
}
