import { useMemo } from 'react';
import { StoriesHeaderProps } from './props';
import styles from './styles.module.scss';

export default function StoriesHeader({
  current,
  count,
  countdown,
}: StoriesHeaderProps) {
  const Items = useMemo(
    () =>
      Array.from({ length: count }).map((_, index) => (
        <div
          key={`header-item-${index}`}
          className={`${styles.container__item} ${index < current ? styles['container__item--seen'] : ''} ${current == index ? styles['container__item--current'] : ''}`}
        >
          {index == current && (
            <div
              className={styles.container__item__progress}
              style={{ width: `${Number((5 - Number(countdown)) / 5) * 100}%` }}
            />
          )}
        </div>
      )),
    [count, current, countdown],
  );

  return <div className={`${styles.container}`}>{Items}</div>;
}
