import TableGameCell from '@/components/core/Table/CustomCells/TableGameCell';
import TableGameBetCell from '@/components/core/Table/CustomCells/TableGameBetCell';
import { TableGridColumn } from '@starsoft/common/src/components/TableGrid/props';
import { UnifiedBet } from '@/models/bet/unified';
import TableUser from '@/components/core/Table/CustomCells/TableUser';

export const latestBetsColumns: TableGridColumn<UnifiedBet>[] = [
  {
    field: 'game.name',
    headerName: 'table_game_name',
    renderCell: unifiedBet => (
      <TableGameCell bet={unifiedBet} type={unifiedBet?.type} />
    ),
  },
  {
    field: 'player.username',
    headerName: 'table_username',
    renderCell: (row: UnifiedBet) => <TableUser player={row?.player} />,
  },
  {
    field: 'amount',
    headerName: 'table_invite_header_bet_amount',
    renderCell: unifiedBet => <TableGameBetCell bet={unifiedBet} showAmount />,
  },
  {
    field: 'profit',
    headerName: 'table_game_win',
    renderCell: (unifiedBet: UnifiedBet) => (
      <TableGameBetCell bet={unifiedBet} isProfitStyle />
    ),
  },
];
