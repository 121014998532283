import { Transition, Variants } from 'framer-motion';

const transition: Transition = { duration: 0.2, ease: 'easeInOut' };

export const slideImage: Variants = {
  hidden: {
    x: 500,
    transition,
  },
  visible: {
    x: 0,
    transition,
  },
  exit: {
    x: -500,
    transition,
  },
};
