import { Tab } from '@package/src/components/Tabs/Tab/props';

export enum BiggestWinFilterOptions {
  '1Day' = '1day',
  '3Days' = '3Days',
  '7Days' = '7Days',
}

export type BiggestWinFilterOptionsProps = Array<Tab<BiggestWinFilterOptions>>;

export interface CarouselsBiggestWinsFilterProps {
  options: BiggestWinFilterOptionsProps;
  currentOption: BiggestWinFilterOptions;
  onOptionClick: (tab: BiggestWinFilterOptions) => void;
}
