import HomeComponent from '@/components/Home/V3';
import { Language } from '@/enums/language';
import { generateCasinoMetadata } from '@/lib/metadata';
import { GetStaticPropsContext, GetStaticPropsResult } from 'next';

export default function HomePage() {
  return <HomeComponent />;
}

export async function getStaticProps({
  locale,
}: GetStaticPropsContext): Promise<GetStaticPropsResult<{ metadata: string }>> {
  const metadata = await generateCasinoMetadata({
    lang: locale as Language,
    path: '/',
  });

  return {
    props: {
      metadata: JSON.stringify(metadata),
    },
    revalidate: 60,
  };
}
