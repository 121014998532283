import styles from './styles.module.scss';
import { StoryButtonProps } from './props';

export default function StoryButton({ action, onClick }: StoryButtonProps) {
  return (
    <div
      onClick={onClick}
      className={`${styles.container} ${action == 'back' ? styles['container--left'] : styles['container--right']}`}
    />
  );
}
