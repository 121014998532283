const BASE_URL: string = process.env.NEXT_PUBLIC_S3_BUCKET_BASE_URL;

export const stories: string[] = [
  `${BASE_URL}/stories/1.png`,
  `${BASE_URL}/stories/2.png`,
  `${BASE_URL}/stories/3.png`,
  `${BASE_URL}/stories/4.png`,
  `${BASE_URL}/stories/5.png`,
  `${BASE_URL}/stories/6.png`,
];
