import { BiggestWinFilterOptions, BiggestWinFilterOptionsProps } from './props';

export const biggesWinsFilterOptions: BiggestWinFilterOptionsProps = [
  {
    label: '1day_filter',
    value: BiggestWinFilterOptions['1Day'],
  },
  {
    label: '3days_filter',
    value: BiggestWinFilterOptions['3Days'],
  },
  {
    label: '7days_filter',
    value: BiggestWinFilterOptions['7Days'],
  },
];
